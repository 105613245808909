<template>
  <div class="content">
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="进店时间">
            <el-date-picker
              v-model="search.first_time_start"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.first_time_end"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input
              v-model="search.customer_name"
              maxlength="30"
              class="w120"
              clearable
              placeholder="请输入"
            />
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="search.telephone"
              maxlength="30"
              class="w120"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="性别">
            <el-select v-model="search.sex" clearable class="w100">
              <el-option
                v-for="item in options.sex"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="年龄">
            <el-input
              v-model="search.agemin"
              maxlength="30"
              class="w84"
              placeholder="最小值"
              clearable
            />
            <span></span>
            <el-input
              v-model="search.agemax"
              maxlength="30"
              class="w84"
              placeholder="最大值"
              clearable
            />
          </el-form-item>
          <el-form-item label="来源">
            <el-select v-model="search.source_id" clearable class="w100">
              <el-option
                v-for="item in options.source_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="客服">
            <el-input
              v-model="search.customer_service_name"
              maxlength="30"
              class="w100"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="会员等级">
            <el-select v-model="search.membership_leve" clearable class="w120">
              <el-option
                v-for="item in options.membership_leve"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
            <el-select
              v-model="search.shop_id"
              clearable
              remote
              filterable
              :remote-method="remoteMethod"
              placeholder="请输入"
              class="w120"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="转店状态">
            <el-select v-model="search.turn_status" clearable class="w100">
              <el-option
                v-for="item in options.turn_status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
		  <el-form-item label="疤痕类型">
		    <el-select v-model="search.scar_type_id" clearable class="w100">
		      <el-option
		        v-for="item in options.scar_type_id"
		        :key="item.id"
		        :label="item.name"
		        :value="item.id"
		      />
		    </el-select>
		  </el-form-item>
          <el-form-item label="疤痕明细">
            <el-select v-model="search.scar_id" clearable class="w120">
              <el-option
                v-for="item in options.scar_id"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="消费金额">
            <el-input
              v-model="search.consumption_min"
              maxlength="30"
              class="w100"
              clearable
              placeholder="最小金额"
            />
            <span></span>
            <el-input
              v-model="search.consumption_max"
              maxlength="30"
              class="w100"
              clearable
              placeholder="最大金额"
            />
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <div class="accountCon">
      <div class="btnBox">
        <el-button
          v-if="!userInfo.shop_id"
          type="primary"
          :disabled="!idList.length"
          class="mb10"
          size="mini"
          @click="hint('导出选中列表')"
        >导出数据</el-button>
      </div>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
            <ElTableColumn v-if="!userInfo.shop_id" type="selection" width="50" />
            <ElTableColumn label="首次进店" width="120" prop="new_join_shop_time" />
            <ElTableColumn label="二次进店" width="120" prop="second_join_shop_time" />
            <ElTableColumn label="姓名" prop="customer_name" />
            <ElTableColumn label="性别" width="50" prop="sex" />
            <ElTableColumn label="年龄" width="50" prop="age" />
            <ElTableColumn label="来源" prop="source_name" />
            <ElTableColumn label="会员等级" prop="grade_name" />
            <ElTableColumn label="护理师" prop="nurse_name" />
            <ElTableColumn label="客服" prop="customer_service_name" />
            <ElTableColumn label="门店/门诊" prop="shop_name" />
            <ElTableColumn label="转店状态">
              <template slot-scope="{ row }">
                <span v-if="row.turn_status==0">无需处理</span>
                <span v-if="row.turn_status==1">待处理</span>
                <span v-if="row.turn_status==2">已处理</span>
              </template>
            </ElTableColumn>
            <ElTableColumn label="修复进展" prop="progress_name" />
            <ElTableColumn label="疤痕类型" width="170" prop="scar_aggregate" />
            <ElTableColumn label="消费金额" prop="consumption" />
            <ElTableColumn label="操作" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme" @click="tapBtn('客户详情',row.id)">查 看</span>
              </template>
            </ElTableColumn>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import {
  clientList,
  clientExport,
  ssettingList,
  getGradeList
} from "@/api/client";
import { getShopList } from "@/api/shop";
class Search {
  first_time_start = "";
  first_time_end = "";
  customer_name = ""; // 客户名称
  telephone = "";
  sex = "";
  agemin = "";
  agemax = "";
  source_id = "";
  customer_service_name = "";
  effective_entry = "";
  membership_leve = "";
  shop_id = "";
  turn_status = "";
  scar_id = "";
  consumption_min = "";
  consumption_max = "";
}
class Options {
  sex = [
    { id: 0, name: "全部" },
    { id: 1, name: "男" },
    { id: 2, name: "女" }
  ];
  effective_entry = [
    { id: 0, name: "全部" },
    { id: 1, name: "是" },
    { id: 2, name: "否" }
  ];
  membership_leve = [];
  shop_id = [];
  source_id = [];
  scar_id = [];
  turn_status = [
    { id: 0, name: "全部" },
    { id: 1, name: "待处理" }
  ];
  scar_type_id = [
    {id: "1",name: "疤痕"},
    {id: "2",name: "青春痘"},
    {id: "3",name: "项目"}
  ];
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "ClientList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(),
      list: [],
      idList: ""
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getOptions();
    if (typeof this.$route.query.search == "object") {
      this.search = this.$route.query.search;
    }
    this.getList();
  },
  methods: {
    // 获取选项
    getOptions() {
      ssettingList({ page: 1, limit: 100000 }).then(res => {
        let that = this;
        res.list.forEach(function(item) {
          if (item.category_id == 1) {
            that.options.scar_id.push(item);
          } else if (item.category_id == 4) {
            that.options.source_id.push(item);
          }
        });
      });
      getGradeList().then(res => {
        this.options.membership_leve = res.list;
      });
    },
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      clientList({ ...this.search, ...this.page, is_join_shop: 1 }).then(
        res => {
          this.list = res.list;
          this.page.total = res.dataCount;
        }
      );
    },
    // 跳转按钮
    tapBtn(routerName, id) {
      const query = {
        id: id,
        activeName: "first",
        search: this.search,
        pageName: "进店列表"
      };
      //this.$router.push({ name: routerName, query });
	  let route = this.$router.resolve({ name: routerName, query });
	  window.open(route.href, '_blank');
    },
    // 提示
    hint(text) {
      this.$confirm(`${text},是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.getExport();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 导出
    getExport() {
      clientExport({ id: this.idList, type: 1 }).then(res => {
        window.open(res.data.url);
      });
    },
    // 改变表格选中状态
    handleSelectionChange(val) {
      this.idList = val.map(i => {
        return i.id;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.mainColor {
  color: #2dc0a3;
}
.content {
  height: calc(100% - 56px);
  overflow-y: auto;
}
.bgW {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
}

.el-form-item {
  margin: 5px 20px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 0px 10px 80px;
  .btnBox {
    display: flex;
    justify-content: space-between;
  }
}
</style>
